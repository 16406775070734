export default {
  name: 'OperationIndex',
  data: function data() {
    return {
      name: '业务',
      breadList: []
    };
  },
  components: {},
  created: function created() {},
  mounted: function mounted() {
    this.getBreadCrumb();
  },
  methods: {
    getBreadCrumb: function getBreadCrumb() {
      var matched = this.$route.matched;
      // this.breadList=[{path:'/',meta:{title:'首页'}}].concat(matched)
      this.breadList = this.$route.matched;
    }
  },
  watch: {
    $route: function $route() {
      this.getBreadCrumb();
    }
  }
};